// sizing
$space: 8px;
$borderRadius: 6px;
$baseFontSize: $space * 2;

// colors
$primaryColorLightest: #95c9ee;
$primaryColorLighter: #57aae5;
$primaryColor: #3498df;
$primaryColorDarker: #1c72b0;
$primaryColorDarkest: #0e3c5d;

$greyLightest: #f7f7f8;
$greyLighter: #ccd0d6;
$grey: #b8bec6;
$greyDarker: #8b94a2;
$greyDarkest: #444b55;

// TODO: these suck
$warnColorLightest: #ffcab8;
$warnColorLighter: #fe9876;
$warnColor: #fe5d26;
$warnColorDarker: #e93b01;
$warnColorDarkest: #892301;
