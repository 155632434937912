@import-normalize;
@import './utils';
@import './typography';

:root {
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu,
    roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
