@import '../../design/variables';

$nav-size: 256px;

.navigation {
  background-color: white;
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  width: 256px;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.layout {
  background-color: $greyLightest;
  min-height: 100vh;
  margin-left: 256px;
}

.list {
  li + li {
    margin-top: $space;
  }
}

.item {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  transition: background-color 0.15s ease-out;
  border-radius: $borderRadius;
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: $greyLightest;

    .itemContent {
      transform: translateX($space);
    }
  }

  &:active {
    .itemContent {
      transform: translateX($space * 1.5);
    }
  }

  & svg {
    margin-right: $space;
  }
}

.itemContent {
  display: flex;
  align-items: center;
  transition: transform 0.15s ease-out;
  transform: translateX(0px);
}

.active,
.active:hover,
.active:focus {
  background-color: $greyLighter;
}

.active:focus {
  .itemContent {
    transform: translateX(0);
  }
}

.logoContainer {
  display: flex;
  align-items: center;
}
