@import './variables';

.h1,
h1 {
  font-size: $baseFontSize * 4;
  font-weight: 700;
}

body {
  font-size: $baseFontSize;
}
