@import './variables';

$spaceAmounts: ($space, $space * 2, $space * 3, $space * 4, $space * 6, $space * 8);
$sides: (top, bottom, left, right);

@each $side in $sides {
  .m#{str-slice($side, 0, 1)}-0 {
    margin-#{$side}: 0;
  }

  .p#{str-slice($side, 0, 1)}-0 {
    padding-#{$side}: 0;
  }
}

@for $i from 1 through length($spaceAmounts) {
  $space: nth($spaceAmounts, $i);

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i} {
      margin-#{$side}: #{$space};
    }

    .p#{str-slice($side, 0, 1)}-#{$i} {
      padding-#{$side}: #{$space};
    }
  }

  .m-#{$i} {
    margin: #{$space};
  }

  .p-#{$i} {
    padding: #{$space};
  }

  .mx-#{$i} {
    margin-left: #{$space};
    margin-right: #{$space};
  }

  .my-#{$i} {
    margin-top: #{$space};
    margin-bottom: #{$space};
  }

  .px-#{$i} {
    padding-left: #{$space};
    padding-right: #{$space};
  }

  .py-#{$i} {
    padding-top: #{$space};
    padding-bottom: #{$space};
  }
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    // background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    // font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    // padding: inherit;
    vertical-align: inherit;
  }
}

.d-block {
  display: block;
}

.list-unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}
